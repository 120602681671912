<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
                <vuexy-logo />

      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        
          <div class="w-100 d-lg-flex flex-column align-items-center justify-content-center px-5">
          <b-img fluid :src="$store.state.mainUserCodes.CollegeLogo" alt="Login V2" style="max-width:550px"/>
          <div class="bg-light-primary p-2 d-flex  w-75 mt-3 rounded-lg">
            <div>
            <h4 class=" font-weight-bold mb-1">Are you a Student?</h4>
            <h6 class=" font-weight-bold">Job hunt on the go with the Flairboat app.</h6>
            <span class="text-body font-medium-1"> Download the app on  Android to be the first to know about new jobs.</span>
            </div>
                      <b-img :src="require('@/assets/images/logo/qrcode.png')" height="100" alt="Flairboat application QR code" />
          </div>
          <!-- <div class="mt-2" v-if="!isMobileLogin">
            <h4 class=" font-weight-bold mb-1">Are you a Recruiter?
            <span class="text-primary font-weight-bold login-text cursor-pointer" @click.prevent="setMobileLogin"> Login Here.</span></h4>
          </div>
          <div class="mt-2" v-else>
            <h6 class=" mb-1">Are you from Training and Placement office?
            <span class="text-primary font-weight-bold login-text cursor-pointer" @click.prevent="setMobileLogin"> Login Here.</span></h6>
          </div> -->
          
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            Sign Up as Recruiter
          </b-card-title>
          <b-card-text class="mb-2">
            Find the best talent at Sarvagya Institute of Technology and Management
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm" #default="{invalid}">
            <b-form class="auth-register-form mt-2" @submit.prevent="register">
              <!-- username -->
              <b-form-group label="Full Name" label-for="register-username">
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="register-username"
                    v-model="username"
                    name="register-username"
                    :state="errors.length > 0 ? false : null"
                    placeholder="johndoe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <!-- <b-form-group label="Email" label-for="register-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->

              <!-- password -->
              <!-- <b-form-group label-for="register-password" label="Password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required|min:8"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->

              <!-- Mobile Number -->
              <b-form-group label-for="mobile-number" label="Mobile Number">
                <validation-provider
                  #default="{ errors }"
                  name="MobileNumber"
                  vid="mobilenumber"
                  rules="required|numeric|min:10|max:10"
                >
                  <b-input-group
                    class="input-group-merge"
                    prepend="+91"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <!-- <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append> -->
                    <b-form-input
                      id="mobile-number"
                      v-model="mobilenumber"
                      type="tel"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="mobile-number"
                      placeholder="9876543210"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="privacy-policy"
                  vid="privacy-policy"
                  rules="required"
                >
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status" value="true"
                  name="privacy-policy"
                >
                  I agree to
                  <b-link>privacy policy & terms</b-link>
                </b-form-checkbox>
                                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
              >
                Sign up
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{ name: 'auth-login' }">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p>

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Register-->
       <div class="w-100 footer-sign">
        <span class="font-weight-light float-md-right  clearfix mb-0 font-small-3">
          Powered by<b-link
        class="ml-25 font-weight-normal"
        href="https://flairboat.com"
        target="_blank"
      >Flairboat</b-link></span>
      </div>
    </b-row>
    <div id="recaptcha-container"></div>
    <modal name="pincodeModal" :clickToClose="false" :adaptive="true">
      <div class="pin-code-wrapper h-100">
        <b-card no-body class="h-100">
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1">
                Verify Mobile Number
              </b-card-title>
              <b-card-text
                >Enter the 6-digit code sent to your mobile number +91-{{
                  mobilenumber
                }}</b-card-text
              >
            </div>
          </b-card-header>
          <b-card-body class=" justify-content-center ">
            <pincode-input
              v-model="code"
              :length="6"
              class="justify-content-center w-100 mt-1"
            />
            <div class="d-flex justify-content-end mt-3 ">
              <b-button variant="outline-secondary" class="mr-2" @click="$modal.hide('pincodeModal')">
                Cancel
              </b-button>
              <b-button variant="primary" @click="loginWithphone">
                Verify
              </b-button>
            </div>
          </b-card-body>
        </b-card>
      </div>
    </modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import PincodeInput from "vue-pincode-input";

import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BImg,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCardText,
  BCard,
} from "bootstrap-vue";
import { required, email, numeric, min, max } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import firebase from "firebase/app";

import { signInWithPhoneNumber } from "firebase/auth";

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCard,
    BCardText,
    BCardTitle,
    BCardHeader,
    BCardSubTitle,
    BCardBody,
    BFormCheckbox,
    BFormGroup,
    BInputGroupPrepend,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    PincodeInput,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "true",
      username: "",
      userEmail: "",
      password: "",
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      // validation
      required,
      email,
      numeric,
      min,
      max,
      istryingLoading: false,
      mobilenumber: "",
      code: "",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          this.submitPhoneNumberAuth()
          //adding firebase login and register feature
          // this.istryingLoading = true;
          // const payload = {
          //   checkbox_remember_me: true,
          //   userDetails: {
          //     email: this.userEmail,
          //     password: this.password,
          //   },
          //   notify: this.$toast,
          //   closeAnimation: (this.istryingLoading = false),
          // };
          // this.$store.dispatch("auth/loginAttempt", payload);
          // if (this.$store.state.auth.isUserLoggedIn) {
          //   try {
          //     localStorage.setItem(
          //       "userData",
          //       JSON.stringify(this.$store.state.auth.AppActiveUser)
          //     );
          //   } catch (err) {
          //     this.$refs.loginForm.setErrors(error.response.data.error);
          //   }
          // }

          ///default template code

          // useJwt.register({
          //   username: this.username,
          //   email: this.userEmail,
          //   password: this.password,
          // })
          //   .then(response => {
          //     useJwt.setToken(response.data.accessToken)
          //     useJwt.setRefreshToken(response.data.refreshToken)
          //     localStorage.setItem('userData', JSON.stringify(response.data.userData))
          //     this.$ability.update(response.data.userData.ability)
          //     this.$router.push('/')
          //   })
          //   .catch(error => {
          //     this.$refs.registerForm.setErrors(error.response.data.error)
          //   })
        }
      });
    },

    submitPhoneNumberAuth() {
            let thisIns = this;

      var phoneNumber = "+91" + this.mobilenumber;
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: function(response) {
            submitPhoneNumberAuth();
          },
        }
      );
      var appVerifier = window.recaptchaVerifier;
                          thisIns.$modal.show("pincodeModal");

      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then(function(confirmationResult) {
          window.confirmationResult = confirmationResult;

        })
        .catch(function(error) {
          console.log(error);
        });
    },
    loginWithphone() {
      // this.$vs.loading();
      let thisIns = this;
      confirmationResult
        .confirm(this.code)
        .then((result) => {
          var user = result;
          console.log(result);
          const payload = {
            userDetails: result,
            notify: this.$toast,
            username:this.username,
            phoneNumber:this.mobilenumber,
            closeAnimation: (this.istryingLoading = false),
          };
          if (user.user.uid){
            console.log(user.user)
            thisIns.$store.dispatch("auth/registerWithPhone", payload);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
                      component: ToastificationContent,
                      position: 'bottom-right',
                      props: {
                        title: 'Error',
                        icon: 'AlertIcon',
                        variant: 'danger',
                        text: `${err.message}`,
                      },
                    })
                             thisIns.$modal.hide("pincodeModal");

          // this.$vs.loading.close();
          localStorage.removeItem("userInfo");
          // this.socialinput = true;
          firebase.auth().signOut();
        });
    },
  },
  mounted() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "normal",
        callback: function(response) {
          console.log("this is success");
          submitPhoneNumberAuth();
        },
      }
    );
  },
  // created(){
  //   window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
  //       "recaptcha-container")
  // }
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.footer-sign{
  position: absolute;
    bottom: 1rem;
    z-index: 1;
    right:3rem;
}
.input-group-text {
  font-size: 1.143rem;
}
input.vue-pincode-input {
  max-width: 50px;
  height: 50px;
  margin: 10px !important;
  &:focus {
    border: 1px solid #039be5 !important;
  }
  // any styles you want for each cell
}
.vm--modal {
  border-radius: 1.143rem;
  background-color: transparent;
  box-shadow: 0px 10px 20px rgb(0 0 0 / 5%);
  border: 1px solid #efefef;
}
</style>
